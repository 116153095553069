/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "9991a1c162ad49e79e513321e47143cd",
    "aws_mobile_analytics_app_region": "ap-northeast-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "9991a1c162ad49e79e513321e47143cd",
            "region": "ap-northeast-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://cigohm7x2rbjfikbfszhw3wzza.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-6dmc6xi455gxrnclkos2qvcsdy",
    "aws_cognito_identity_pool_id": "ap-northeast-1:33cdb194-145f-493e-8af6-3759be6c43b7",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_OMqZyNrc9",
    "aws_user_pools_web_client_id": "5urv2fpb5ikj3nut5lmfjor8ro",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "samplegraphql-20230418224859-hostingbucket-main",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "http://samplegraphql-20230418224859-hostingbucket-main.s3-website-ap-northeast-1.amazonaws.com",
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "tenant-main",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
